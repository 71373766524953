
























import { showSelectPharmacyModal } from "@/components/shared/SelectPharmacyModal.vue";
import TheHeaderDropdownAccount from "@/containers/TheHeaderDropdownAccount.vue";
import { auth0PharmacyIds, hasAuth0AdminRole } from "@/store/auth0Store";
import { Pharmacy, usePharmaciesStore } from "@/store/pharmaciesStore";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: { TheHeaderDropdownAccount },
  setup() {
    const pharmaciesStore = usePharmaciesStore();
    const selectingPharmacyStore = useSelectingPharmacyStore();

    const selectPharmacyButton = computed(() => {
      if (selectingPharmacyStore.selectingPharmacyId.value) {
        pharmaciesStore.isFetching.value;
        const pharmacy = pharmaciesStore.get(selectingPharmacyStore.selectingPharmacyId.value) as Pharmacy;
        if (pharmacy) {
          return pharmacy.name;
        }
      }

      return "薬局を選択してください";
    });

    const selectPharmacyButtonColor = computed(() => {
      if (hasAuth0AdminRole) {
        if (auth0PharmacyIds.indexOf(selectingPharmacyStore.selectingPharmacyId.value) === -1) {
          return "info";
        } else {
          return "danger";
        }
      }
      return "primary";
    });

    return {
      core_url: process.env.VUE_APP_V2_APP_CORE_HOST,
      medorder_url: process.env.VUE_APP_V2_APP_MEDORDER_HOST,
      selectPharmacyButton,
      selectPharmacyButtonColor,
      showSelectPharmacyModal,
    };
  },
});
