import { dateToEraYear, zeroPadding } from "./ReceiptDateUtility";

const COLUMN_NAME = 4;
const COLUMN_GENDER = 5;
const COLUMN_BIRTHDAY = 6;
const COLUMN_PATIENT_ID = 34;

const ERA_YEAR_DATE_LENGTH = 7;
const GREGORIAN_YEAR_DATE_LENGTH = 8;

export const ReLineAnonymizer = {
  anonymize: function (columns: string[]): string | null {
    const genderBirthday = Number(columns[COLUMN_GENDER] + restoreEraBirthday(columns[COLUMN_BIRTHDAY]));
    columns.splice(COLUMN_NAME, 4, generateRxId(genderBirthday, columns[COLUMN_NAME]));
    columns.splice(COLUMN_PATIENT_ID - COLUMN_NAME + 1);
    return columns.join(",");
  },
};

function restoreEraBirthday(birthday: string): string {
  if (birthday.length === ERA_YEAR_DATE_LENGTH) {
    return birthday;
  } else if (birthday.length === GREGORIAN_YEAR_DATE_LENGTH) {
    const year = parseInt(birthday.substr(0, 4), 10);
    const month = parseInt(birthday.substr(4, 2), 10);
    const day = parseInt(birthday.substr(6, 2), 10);

    const { era, eraYear } = dateToEraYear(year, month, day);
    return `${era}${zeroPadding(eraYear, 2)}${zeroPadding(month, 2)}${zeroPadding(day, 2)}`;
  } else {
    throw Error(`Invalid birthday ${birthday}`);
  }
}

// from original receipt parser
function generateRxId(genderBirthday: number, name: string): string {
  const firstCode = Number(name.charCodeAt(name.length - 2).toString(6)) + Number(name.charCodeAt(name.length - 1).toString(10));
  const secondCode = Number(name.charCodeAt(1).toString(7)) + Number(name.charCodeAt(2).toString(3));
  const criteria = Number(name.charCodeAt(2).toString(9)) + Number(name.charCodeAt(name.length - 2).toString(5));

  if (criteria % 6 === 0) {
    return genderBirthday.toString(16) + firstCode.toString(16) + secondCode.toString(16);
  } else if (criteria % 6 === 1) {
    return genderBirthday.toString(16) + secondCode.toString(16) + firstCode.toString(16);
  } else if (criteria % 6 === 2) {
    return firstCode.toString(16) + secondCode.toString(16) + genderBirthday.toString(16);
  } else if (criteria % 6 === 3) {
    return firstCode.toString(16) + genderBirthday.toString(16) + secondCode.toString(16);
  } else if (criteria % 6 === 4) {
    return secondCode.toString(16) + genderBirthday.toString(16) + firstCode.toString(16);
  } else if (criteria % 6 === 5) {
    return secondCode.toString(16) + firstCode.toString(16) + genderBirthday.toString(16);
  } else {
    throw new Error();
  }
}
