<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="8">
        <div class="w-100">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">403</h1>
            <h4 class="pt-3">あなたにはアクセス権がありません。</h4>
            <p class="text-muted">
              ログインするアカウントが間違っているか、権限が削除された可能性があります。<br />
              管理者に確認してください。
            </p>
            <CButton color="danger" class="float-right" @click="logout">ログアウト</CButton>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { useAuth0Store } from "@/store/auth0Store";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup() {
    const auth0Store = useAuth0Store();

    return {
      ...auth0Store,
    };
  },
});
</script>
