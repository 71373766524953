import { authGuard } from "@/auth/authGuard";
import EmptyContainer from "@/containers/EmptyContainer.vue";
import TheContainer from "@/containers/TheContainer.vue";
import ThePublicContainer from "@/containers/ThePublicContainer.vue";
import About from "@/views/About.vue";
import DealImageEdit from "@/views/dealImages/DealImageEdit.vue";
import DealDetail from "@/views/deals/DealDetail.vue";
import DealEdit from "@/views/deals/DealEdit.vue";
import DealPutUp from "@/views/deals/DealPutUp.vue";
import Deals from "@/views/deals/Deals.vue";
import PutUpRecommendations from "@/views/deals/PutUpRecommendations.vue";
import DispensedItems from "@/views/dispensedItems/DispensedItems.vue";
import Page403 from "@/views/Page403.vue";
import Page404 from "@/views/Page404.vue";
import DealImageUpload from "@/views/public/DealImageUpload.vue";
import ReceiptImportFiles from "@/views/receipts/ReceiptImportFiles.vue";
import ReceiptPortal from "@/views/receipts/ReceiptPortal.vue";
import ResetPharmacy from "@/views/ResetPharmacy.vue";
import SelectPharmacy from "@/views/SelectPharmacy.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: "/forbidden", component: Page403 },
  {
    path: "/public",
    component: ThePublicContainer,
    children: [{ path: "deal-images", name: "出品画像の登録", component: DealImageUpload }],
  },
  {
    path: "/",
    component: TheContainer,
    beforeEnter: authGuard,
    children: [
      { path: "", redirect: "/deals" },
      {
        path: "deals",
        redirect: "deals",
        name: "取引",
        component: EmptyContainer,
        children: [
          { path: "/", redirect: "market" },

          { path: "market", name: "購入", component: Deals, props: { type: "market" } },
          { path: "buying", name: "購入手続き中", component: Deals, props: { type: "buying" } },

          { path: "put-up-recommendations", name: "不動在庫", component: PutUpRecommendations },
          { path: "put-up", name: "出品", component: DealPutUp, props: true },
          { path: "selling", name: "出品中の医薬品", component: Deals, props: { type: "selling" } },
          { path: "sell_dealing", name: "販売手続き中", component: Deals, props: { type: "sell_dealing" } },

          { path: "done", name: "過去の取引", component: Deals, props: { type: "done" } },
          {
            path: ":id",
            redirect: ":id",
            name: "取引詳細",
            meta: { label: "詳細" },
            component: EmptyContainer,
            children: [
              { path: "/", name: "取引詳細表示", meta: { label: "表示" }, component: DealDetail },
              { path: "edit", name: "取引編集", meta: { label: "編集" }, component: DealEdit },
              { path: "edit-images", name: "取引画像編集", meta: { label: "画像編集" }, component: DealImageEdit },
            ],
          },
        ],
      },
      {
        path: "dispensed_items",
        redirect: "config",
        name: "在庫表示",
        component: EmptyContainer,
        children: [
          { path: "/", redirect: "config" },
          { path: "config", name: "在庫表示を整理", meta: { label: "整理" }, component: DispensedItems },
        ],
      },
      {
        path: "receipts",
        redirect: "receipts",
        name: "レセプト",
        component: EmptyContainer,
        children: [
          { path: "/", name: "レセプト登録", component: ReceiptPortal },
          { path: "files", name: "レセプト登録ステータス", component: ReceiptImportFiles },
        ],
      },
      { path: "config", name: "設定", component: About },
    ],
  },
  { path: "/select-pharmacy", component: SelectPharmacy, beforeEnter: authGuard },
  { path: "/reset-pharmacy", component: ResetPharmacy },
  { path: "*", component: Page404 },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
