










































import TextCopyButton from "@/components/shared/TextCopyButton.vue";
import { Pharmacy } from "@/models/Pharmacy";
import { PharmacyLicense } from "@/models/PharmacyLicense";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  components: { TextCopyButton },
  props: {
    pharmacy: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isShow = ref<boolean>(false);
    const isLicenseLoaded = ref<boolean>(false);
    const pharmacyLicense = ref<PharmacyLicense>();

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId.value;

    const isMyPharmacy = computed(() => (props.pharmacy as Pharmacy).id === myPharmacyId);

    function showModal() {
      isShow.value = true;
      // isLicenseLoaded.value = true;
    }

    return {
      isShow,
      isLicenseLoaded,
      pharmacyLicense,
      isMyPharmacy,
      showModal,
    };
  },
});
