




















































































import SelectGroups from "@/components/groups/SelectGroups.vue";
import SelectDealableItems from "@/components/search/SelectDealableItems.vue";
import { DealableItem } from "@/models/DealableItem";
import { useHttpMedshare } from "@/plugins/axios";
import { useRouter } from "@/router/useRouter";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent, ref } from "@vue/composition-api";
import { DateTime } from "luxon";
import Multiselect from "vue-multiselect";

export default defineComponent({
  components: { Multiselect, SelectDealableItems, SelectGroups },
  props: {
    dealable_item: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const router = useRouter();
    const httpMedshare = useHttpMedshare();

    const isLoading = ref<boolean>(true);
    const errors = ref<string[]>([]);

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId.value;

    const selectedGroupIds = ref<number[]>([]);

    const dealableItemId = ref<number | null>(null);
    const dealableItem = ref<DealableItem | null>(null);
    if (props && props.dealable_item) {
      dealableItem.value = props.dealable_item as DealableItem;
      dealableItemId.value = dealableItem.value.id;
    }

    const unit = ref<number>(1);
    const discount_rate = ref<number>(40);
    const unpacked = ref<boolean | null>(null);
    const expires_on = ref<string | null>(null);
    const lot_number = ref<string | null>(null);
    const comment = ref<string | null>(null);

    const discountRateTooltip = {
      html: true,
      content: "期限1年以上: 40%<br />半年〜1年: 50%<br />半年以内: 60％<br /><br />ジェネリックはさらに20％加えた率を推奨しております。",
    };

    const shouldDisableSaveButton = computed(() => !dealableItem.value);
    const price = computed(() => {
      return dealableItem.value ? Math.round((dealableItem.value.price * unit.value * (100 - discount_rate.value)) / 100) : null;
    });
    const thisMonth = DateTime.now().toFormat("YYYY-MM");

    function changeUnpacked(value: boolean) {
      unpacked.value = value;
    }

    async function save() {
      errors.value = [];

      try {
        if (dealableItem.value === null) return;
        if (expires_on.value === null) return;

        const expiresOn = DateTime.fromFormat(expires_on.value, "yyyy-MM").endOf("month").toFormat("yyyy-MM-dd");

        const req_params = {
          dealable_item_id: dealableItem.value.id,
          unit: unit.value,
          discount_rate: discount_rate.value,
          unpacked: unpacked.value,
          expires_on: expiresOn,
          lot_number: lot_number.value,
          comment: comment.value,
          group_ids: selectedGroupIds.value.join(","),
        };

        const response = await httpMedshare.post(`/api/v2/pharmacy/pharmacies/${myPharmacyId}/deals`, req_params);
        const id = response.data.id;

        await router.push({ name: "取引詳細", params: { id: id.toString() } });
      } catch (e) {
        errors.value = [e.message];
        throw e;
      }
    }

    return {
      isLoading,
      errors,
      selectedGroupIds,
      dealableItemId,
      dealableItem,
      unit,
      discount_rate,
      unpacked,
      expires_on,
      lot_number,
      comment,
      discountRateTooltip,
      shouldDisableSaveButton,
      price,
      thisMonth,
      changeUnpacked,
      save,
    };
  },
});
