























import { DealableItem } from "@/models/DealableItem";
import { useHttpMedshare } from "@/plugins/axios";
import { defineComponent, ref, watch } from "@vue/composition-api";
import Multiselect from "vue-multiselect";

export default defineComponent({
  components: { Multiselect },
  props: {
    selected: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    const httpMedshare = useHttpMedshare();

    const isSearching = ref<boolean>(false);
    const toggleDropdown = ref<boolean>(false);
    const selectedValue = ref<DealableItem | undefined>(undefined);
    if (props && props.selected) {
      selectedValue.value = props.selected as DealableItem;
    }

    const values = ref<DealableItem[]>([]);

    async function search(query?: string) {
      if (isSearching.value) return;

      try {
        isSearching.value = true;
        const req_params = { query: query };
        const response = await httpMedshare.get(`/api/v2/master/dealable_items`, { params: req_params });
        values.value = response.data.map((d: Record<string, unknown>) => new DealableItem(d));
      } finally {
        isSearching.value = false;
      }
    }

    function customLabel(item: DealableItem) {
      return `${item.name} (${item.price} 円 / ${item.unit})`;
    }

    watch(selectedValue, () => {
      context.emit("update:selected", selectedValue.value);
    });

    search();

    return {
      isSearching,
      toggleDropdown,
      selectedValue,
      values,
      search,
      customLabel,
    };
  },
});
