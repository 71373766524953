

























import { useAuth0Store } from "@/store/auth0Store";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup() {
    const auth0Store = useAuth0Store();

    return {
      itemsCount: 42,
      core_url: process.env.VUE_APP_V2_APP_CORE_HOST,
      ...auth0Store,
    };
  },
});
