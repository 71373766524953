
















































































import SelectGroups from "@/components/groups/SelectGroups.vue";
import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import { Deal } from "@/models/Deal";
import { useHttpMedshare } from "@/plugins/axios";
import { useRouter } from "@/router/useRouter";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent, ref } from "@vue/composition-api";
import { DateTime } from "luxon";

export default defineComponent({
  components: { LoadingAlert, SelectGroups },
  setup() {
    const router = useRouter();
    const httpMedshare = useHttpMedshare();

    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(true);
    const errors = ref<string[]>([]);

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId.value;

    const dealId = router.currentRoute.params.id;
    const dealUrl = `/api/v2/pharmacy/pharmacies/${myPharmacyId}/deals/${dealId}`;
    const deal = ref<Deal | null>(null);
    const expires_on = ref<string | null>(null);
    const selectedGroupIds = ref<number[]>([]);

    const discountRateTooltip = {
      html: true,
      content: "期限1年以上: 40%<br />半年〜1年: 50%<br />半年以内: 60％<br /><br />ジェネリックはさらに20％加えた率を推奨しております。",
    };

    const shouldDisableSaveButton = computed(() => !deal.value);
    const price = computed(() => {
      const d = deal.value;
      return d ? Math.round((d.unit_price * d.unit * (100 - d.discount_rate)) / 100) : null;
    });
    const thisMonth = DateTime.now().toFormat("YYYY-MM");

    async function load() {
      const response = await httpMedshare.get(dealUrl);

      deal.value = new Deal(response.data);
      expires_on.value = DateTime.fromISO(deal.value.expires_on).toFormat("yyyy-MM");
      selectedGroupIds.value = deal.value.groups?.map((g) => g.id) || [];

      isFirstLoading.value = false;
      isLoading.value = false;
    }

    function changeUnpacked(value: boolean) {
      if (deal.value) deal.value.unpacked = value;
    }

    async function save() {
      errors.value = [];

      try {
        if (deal.value === null) return;
        if (expires_on.value === null) return;

        const expiresOn = DateTime.fromFormat(expires_on.value, "yyyy-MM").endOf("month").toFormat("yyyy-MM-dd");

        const req_params = {
          unit: deal.value.unit,
          discount_rate: deal.value.discount_rate,
          unpacked: deal.value.unpacked,
          expires_on: expiresOn,
          lot_number: deal.value.lot_number,
          comment: deal.value.comment,
          group_ids: selectedGroupIds.value.join(","),
        };

        await httpMedshare.patch(dealUrl, req_params);
        await router.push({ name: "取引詳細", params: { id: dealId.toString() } });
      } catch (e) {
        errors.value = [e.message];
        throw e;
      }
    }

    load();

    return {
      isFirstLoading,
      isLoading,
      errors,
      deal,
      expires_on,
      selectedGroupIds,
      discountRateTooltip,
      shouldDisableSaveButton,
      price,
      thisMonth,
      changeUnpacked,
      save,
    };
  },
});
