























import { hasAuth0AdminRole } from "@/store/auth0Store";
import { computed, defineComponent } from "@vue/composition-api";
import { Pharmacy, usePharmaciesStore } from "@/store/pharmaciesStore";

export default defineComponent({
  name: "SelectPharmacyTable",
  props: {
    buttonName: {
      type: String,
      default: "選択",
      // ボタン名を「切り替え」などに変えられるようにpropsとする
    },
  },
  setup(props, context) {
    const pharmaciesStore = usePharmaciesStore();

    const fields = computed(() => {
      const baseFields = [
        { key: "id", label: "ID" },
        { key: "name", label: "薬局名" },
        { key: "micode", label: "医療機関コード" },
        { key: "actions", label: props.buttonName },
      ];

      if (hasAuth0AdminRole) {
        baseFields.splice(3, 0, { key: "address1", label: "都道府県" });
      }
      return baseFields;
    });

    async function selectPharmacy(pharmacy: Pharmacy) {
      context.emit("selectPharmacy", pharmacy);
    }

    // 所属薬局の一覧を取得する。
    pharmaciesStore.findList();

    return {
      fields,
      isLoading: pharmaciesStore.isFetching,
      pharmacies: pharmaciesStore.list,
      selectPharmacy,
    };
  },
});
