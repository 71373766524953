



























import { Group } from "@/models/Group";
import { useHttpCore } from "@/plugins/axios";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  props: {
    selectedIds: {
      type: Array,
      default: [],
    },
  },
  setup(props, context) {
    const httpCore = useHttpCore();

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId.value;

    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(false);
    const isPublic = ref<boolean>(props.selectedIds.length === 0);
    const myGroups = ref<Group[]>([]);
    const selectableGroups = ref<Array<Record<string, unknown>>>([]);

    async function loadGroups() {
      try {
        isLoading.value = true;

        const response = await httpCore.get(`/api/v2/pharmacy/pharmacies/${myPharmacyId}/groups`);
        myGroups.value = response.data.map((d: Record<string, unknown>) => new Group(d));
        selectableGroups.value = myGroups.value.map((g) => ({ id: g.id, value: props.selectedIds.includes(g.id), label: g.name }));
        isFirstLoading.value = false;
      } finally {
        isLoading.value = false;
      }
    }
    loadGroups();

    watch(isPublic, () => {
      if (isPublic.value === true) context.emit("update:selectedIds", []);
      else
        context.emit(
          "update:selectedIds",
          selectableGroups.value.filter((g) => g.value).map((g) => g.id)
        );
    });

    watch(
      selectableGroups,
      () => {
        context.emit(
          "update:selectedIds",
          selectableGroups.value.filter((g) => g.value).map((g) => g.id)
        );
      },
      { deep: true }
    );

    return {
      isFirstLoading,
      isLoading,
      isPublic,
      selectableGroups,
    };
  },
});
