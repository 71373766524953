
































import { Deal } from "@/models/Deal";
import { computed, defineComponent } from "@vue/composition-api";
import { DateTime } from "luxon";
import StepProgress from "vue-step-progress";

export default defineComponent({
  components: { StepProgress },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const deal = props.deal as Deal;
    const expires_on = computed(() => DateTime.fromISO(deal.expires_on).toFormat("yyyy-MM"));

    return {
      expires_on,
    };
  },
});
