import { Deal } from "@/models/Deal";
import { DealableItem } from "@/models/DealableItem";
import { Pharmacy } from "@/models/Pharmacy";
import { DateTime } from "luxon";

export class DispensedItem {
  id!: number;
  pharmacy_id!: number;
  pharmacy!: Pharmacy;
  dealable_item_id!: number;
  "dealable_items.name"!: string; // CDataTable のソートのために定義
  "dealable_items.price"!: number; // CDataTable のソートのために定義
  dealable_item!: DealableItem;
  dispensation_count!: number;
  patient_count!: number;
  last_dispensed_on!: string;
  last_dispensed_on_datetime!: DateTime;
  status!: string;
  last_deal_id?: number;
  last_deal?: Deal;
  last_deal_updated_on?: string;
  created_at!: string;
  updated_at!: string;

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
    this.pharmacy = new Pharmacy(init?.pharmacy as Record<string, unknown>);
    if (init?.dealable_item) {
      this.dealable_item = new DealableItem(init?.dealable_item as Record<string, unknown>);
      this["dealable_items.name"] = this.dealable_item.name;
      this["dealable_items.price"] = this.dealable_item.price;
    }
    this.last_dispensed_on_datetime = DateTime.fromISO(init?.last_dispensed_on as string);
    if (this.last_deal) {
      this.last_deal = new Deal(init?.last_deal as Record<string, unknown>);
      this.last_deal_updated_on = DateTime.fromISO(this.last_deal.updated_at).toISODate();
    }
  }
}
