export class Receipt {
  id!: number;
  pharmacy_id!: string;
  receipt_import_file_id!: string;
  micode!: string;
  year!: string;
  month!: string;
  insurance!: string;
  file_sequence?: string;
  created_at!: string;
  updated_at!: string;

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
  }

  get display_insurance(): string {
    switch (this.insurance) {
      case "1":
        return "社保";
      case "2":
        return "国保";
      default:
        return "不明";
    }
  }
}
