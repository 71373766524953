




















import { useRouter } from "@/router/useRouter";
import { defineComponent, ref, onMounted } from "@vue/composition-api";
import { Pharmacy, usePharmaciesStore } from "@/store/pharmaciesStore";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import SelectPharmacyTable from "@/components/shared/SelectPharmacyTable.vue";

export default defineComponent({
  name: "SelectPharmacy",
  components: {
    SelectPharmacyTable,
  },

  setup() {
    const router = useRouter();

    const pharmaciesStore = usePharmaciesStore();
    const selectingPharmacyStore = useSelectingPharmacyStore();

    const isLoading = ref(true);
    const pharmacies = pharmaciesStore.list;

    async function routerNext() {
      // とりあえずは、トップページへ移動。
      // TODO: その前にいたページを記録して移動させたい。
      await router.push({ name: "取引" });
    }

    async function selectPharmacy(pharmacy: Pharmacy) {
      await selectingPharmacyStore.selectPharmacyId(pharmacy.id);
      await routerNext();
    }

    onMounted(async () => {
      // 所属薬局の一覧を取得する。
      await pharmaciesStore.findList();

      // すでに選択薬局があれば、ページ移動。
      const selectingPharmacyId = selectingPharmacyStore.selectingPharmacyId.value;
      if (selectingPharmacyId) {
        if (pharmacies.value.find((pharmacy) => pharmacy.id === selectingPharmacyId)) {
          await routerNext();
        }
      }

      // 薬局が1つであれば、選択薬局として記録してページ移動。
      if (pharmacies.value.length === 1) {
        await selectPharmacy(pharmacies.value[0]);
      }

      // 薬局が複数の場合は、選択画面を表示させる。
      isLoading.value = false;
    });

    return {
      isLoading,
      pharmacies,
      routerNext,
      selectPharmacy,
    };
  },
});
