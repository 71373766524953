import { getCurrentInstance } from "@vue/composition-api";
import Axios, { AxiosInstance } from "axios";
import { getAuth0JwtToken } from "@/store/auth0Store";

export function createHttp(host?: string): AxiosInstance {
  const http = Axios.create({});

  if (host) {
    http.interceptors.request.use(async (request) => {
      // Authorization 付与
      const jwtToken = await getAuth0JwtToken();
      request.headers.Authorization = `Bearer ${jwtToken}`;

      // URL にホスト名を付与
      request.url = host + request.url;

      return request;
    });
  }

  return http;
}

export function useHttpExternal(): AxiosInstance {
  const instance = getCurrentInstance();

  // `setup()`外で使用するとインスタンスは取得できません
  // https://v3.vuejs.org/api/composition-api.html#getcurrentinstance
  if (!instance) throw new Error(`Should be used in setup().`);

  /* eslint-disable */
  // @ts-ignore
  return instance.proxy.$httpExternal;
  /* eslint-enable */
}

export function useHttpCore(): AxiosInstance {
  const instance = getCurrentInstance();

  // `setup()`外で使用するとインスタンスは取得できません
  // https://v3.vuejs.org/api/composition-api.html#getcurrentinstance
  if (!instance) throw new Error(`Should be used in setup().`);

  /* eslint-disable */
  // @ts-ignore
  return instance.proxy.$httpCore;
  /* eslint-enable */
}

export function useHttpMedshare(): AxiosInstance {
  const instance = getCurrentInstance();

  // `setup()`外で使用するとインスタンスは取得できません
  // https://v3.vuejs.org/api/composition-api.html#getcurrentinstance
  if (!instance) throw new Error(`Should be used in setup().`);

  /* eslint-disable */
  // @ts-ignore
  return instance.proxy.$httpMedshare;
  /* eslint-enable */
}
