import { DealableItem } from "@/models/DealableItem";
import { DispensedItem } from "@/models/DispensedItem";
import { Group } from "@/models/Group";
import { Pharmacy } from "@/models/Pharmacy";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { DateTime } from "luxon";

export class Deal {
  id!: number;
  groups_count!: number;
  groups!: Group[];
  dealable_item_id!: number;
  dealable_item!: DealableItem;
  "dealable_items.name"!: string;
  dispensed_item!: DispensedItem;
  seller_pharmacy_id!: number;
  seller_pharmacy!: Pharmacy;
  buyer_pharmacy_id?: number;
  buyer_pharmacy?: Pharmacy;
  status!: string;
  price!: number;
  official_price!: number;
  unit_price!: number;
  unit!: number;
  discount_rate!: number;
  unpacked!: boolean;
  expires_on!: string;
  lot_number!: string;
  comment!: string;
  images_count!: number;
  created_at!: string;
  updated_at!: string;

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
    this.seller_pharmacy = new Pharmacy(init?.seller_pharmacy as Record<string, unknown>);
    this.buyer_pharmacy = new Pharmacy(init?.buyer_pharmacy as Record<string, unknown>);
    if (init?.dealable_item) {
      this.dealable_item = new DealableItem(init?.dealable_item as Record<string, unknown>);
      this["dealable_items.name"] = this.dealable_item.name;
    }
    if (init?.groups) {
      this.groups = (init?.groups as Array<Record<string, unknown>>).map((group) => new Group(group));
    }
  }

  get display_status(): string {
    switch (this.status) {
      case "OPEN":
        return "出品中";
      case "DEALING":
        return "取引中";
      case "CLOSE":
        return "取引済み";
      case "CANCEL":
        return "取引キャンセル";
      default:
        return "不明";
    }
  }

  get display_relative_status(): string {
    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId.value;

    switch (this.status) {
      case "OPEN":
        return "出品中";
      case "DEALING":
        if (this.seller_pharmacy_id === myPharmacyId) return "売却手続中";
        else if (this.buyer_pharmacy_id === myPharmacyId) return "購入手続中";
        else return "取引中";
      case "CLOSE":
        if (this.seller_pharmacy_id === myPharmacyId) return "売却済み";
        else if (this.buyer_pharmacy_id === myPharmacyId) return "購入済み";
        else return "取引済み";
      case "CANCEL":
        if (this.seller_pharmacy_id === myPharmacyId) return "出品キャンセル";
        else if (this.buyer_pharmacy_id === myPharmacyId) return "購入キャンセル";
        else return "取引キャンセル";
      default:
        return "不明";
    }
  }

  get display_unpacked(): string {
    switch (this.unpacked) {
      case true:
        return "開封済み";
      case false:
        return "未開封";
      default:
        return "不明";
    }
  }

  public display_expires_on(): string {
    const dateLuxon = DateTime.fromISO(this.expires_on.toString());
    return dateLuxon.toFormat("yyyy/MM");
  }

  public days_until_expire(): number {
    const dateLuxon = DateTime.fromISO(this.expires_on.toString());
    return Math.round(dateLuxon.diffNow("days").days);
  }

  public color_expire(): string {
    const days_until_expire = this.days_until_expire();
    if (days_until_expire > 365) {
      return "";
    } else if (days_until_expire > 180) {
      return "text-info";
    } else if (days_until_expire > 30) {
      return "text-warning";
    } else {
      return "text-danger";
    }
  }

  public color_unpacked(): string {
    switch (this.unpacked) {
      case true:
        return "warning";
      case false:
        return "info";
      default:
        return "";
    }
  }
}
