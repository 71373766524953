import {
  cilArrowThickRight,
  cilCart,
  cilCheckCircle,
  cilEnvelopeClosed,
  cilFile,
  cilFilter,
  cilHistory,
  cilImagePlus,
  cilInfo,
  cilLocationPin,
  cilPencil,
  cilPhone,
  cilPlus,
  cilReportSlash,
  cilSearch,
  cilSettings,
  cilSwapHorizontal,
  cilTag,
  cilTruck,
  cilXCircle,
} from "@coreui/icons";

export const iconsSet = Object.assign(
  {},
  {
    cilArrowThickRight,
    cilCart,
    cilCheckCircle,
    cilEnvelopeClosed,
    cilFile,
    cilFilter,
    cilHistory,
    cilImagePlus,
    cilInfo,
    cilLocationPin,
    cilPencil,
    cilPhone,
    cilPlus,
    cilReportSlash,
    cilSearch,
    cilSettings,
    cilSwapHorizontal,
    cilTag,
    cilTruck,
    cilXCircle,
  }
);
