











































































































import DispensedItemStatusButton from "@/components/dispensedItems/DispensedItemStatusButton.vue";
import ReceiptUploadStatus from "@/components/receipts/ReceiptUploadStatus.vue";
import DatetimeSpan from "@/components/shared/datetime/DatetimeSpan.vue";
import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import { DispensedItem } from "@/models/DispensedItem";
import { useHttpMedshare } from "@/plugins/axios";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { DateTime } from "luxon";

export default defineComponent({
  components: { DatetimeSpan, DispensedItemStatusButton, LoadingAlert, ReceiptUploadStatus },
  setup() {
    const httpMedshare = useHttpMedshare();

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId.value;
    const url = computed(() => `/api/v2/pharmacy/pharmacies/${myPharmacyId}/dispensed_items`);

    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(false);
    const deadStockDays = ref<number | null>(180);
    const query = ref<string>("");
    const items = ref<DispensedItem[]>([]);
    const fields = [
      { key: "sell", label: "出品", sorter: false, _style: "width: 70px" },
      { key: "dealable_items.name", label: "医薬品名" },
      { key: "dealable_items.price", label: "薬価" },
      { key: "last_dispensed_on", label: "最終調剤日" },
      { key: "dispensation_count", label: "調剤回数" },
      { key: "patient_count", label: "推定患者数" },
      { key: "last_deal_id", label: "直近の取引" },
      { key: "status", label: "表示設定", _style: "width: 240px" },
    ];
    const sorter = ref<Record<string, string | boolean>>({ external: true, resetable: false });
    const sorterValue = ref<Record<string, string | boolean>>({ column: "last_dispensed_on", asc: false });
    const sorterField = computed(() => (sorterValue.value.asc ? sorterValue.value.column : `-${sorterValue.value.column}`));
    const currentPage = ref<number>(1);
    const totalPages = ref<number>(1);
    const totalCount = ref<number>(0);

    async function fetchItems() {
      if (isLoading.value) return;

      try {
        isLoading.value = true;
        const req_params = {
          page: currentPage.value,
          query: query.value,
          sort: sorterField.value,
          dead_stock_days: deadStockDays.value,
          status: "IN_USE",
        };

        const response = await httpMedshare.get(url.value, { params: req_params });
        items.value = response.data.map((d: Record<string, unknown>) => new DispensedItem(d));

        currentPage.value = Number(response.headers["x-current-page"]);
        totalPages.value = Number(response.headers["x-total-pages"]);
        totalCount.value = Number(response.headers["x-total-count"]);

        isFirstLoading.value = false;
      } finally {
        isLoading.value = false;
      }
    }

    watch(currentPage, () => fetchItems());
    watch(sorterValue, () => fetchItems());

    function search(): void {
      currentPage.value = 1;
      fetchItems();
    }

    function toRelativeDate(x: string): string | null {
      return DateTime.fromFormat(x, "yyyy-MM-dd").toRelative();
    }

    fetchItems();

    return {
      isFirstLoading,
      isLoading,
      deadStockDays,
      query,
      items,
      fields,
      sorter,
      sorterValue,
      currentPage,
      totalPages,
      totalCount,
      search,
      toRelativeDate,
    };
  },
});
