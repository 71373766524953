



























import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  setup(props, context) {
    // 表示非表示
    const minimize = ref<boolean>(false);
    const show = ref<boolean | string>("responsive");

    context.root.$on("toggle-sidebar", () => {
      const sidebarOpened = show.value === true || show.value === "responsive";
      show.value = sidebarOpened ? false : "responsive";
    });
    context.root.$on("toggle-sidebar-mobile", () => {
      const sidebarClosed = show.value === "responsive" || show.value === false;
      show.value = sidebarClosed ? true : "responsive";
    });

    function flipMinimize() {
      minimize.value = !minimize.value;
    }

    return {
      minimize,
      show,
      flipMinimize,
    };
  },
});
