










































































































































































import DealBuyModal from "@/components/deals/DealBuyModal.vue";
import DealGroupsCell from "@/components/deals/DealGroupsCell.vue";
import PharmacyLink from "@/components/pharmacies/PharmacyLink.vue";
import DatetimeSpan from "@/components/shared/datetime/DatetimeSpan.vue";
import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import { Deal } from "@/models/Deal";
import { DispensedItem } from "@/models/DispensedItem";
import { useHttpMedshare } from "@/plugins/axios";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  components: { DatetimeSpan, DealBuyModal, DealGroupsCell, LoadingAlert, PharmacyLink },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const httpMedshare = useHttpMedshare();

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId.value;
    const urlBase = computed(() => `/api/v2/pharmacy/pharmacies/${myPharmacyId}`);
    const dealUrl = computed(() => `${urlBase.value}/deals/${props.type}`);

    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(false);
    const isOpenBuyModal = ref<boolean>(false);
    const query = ref<string>("");
    const dispensingDays = ref<number | null>(90);
    const deals = ref<Deal[]>([]);
    const selectedDeal = ref<Deal>();
    const fields = computed(() => {
      switch (props.type) {
        case "market":
          return [
            { key: "groups", label: "出品先" },
            { key: "actions_default", label: "操作", sorter: false },
            { key: "dealable_items.name", label: "医薬品名" },
            { key: "price", label: "出品額" },
            { key: "expires_on", label: "使用期限" },
            { key: "unpacked", label: "開封状況" },
            { key: "seller_pharmacy_id", label: "出品薬局" },
            { key: "created_at", label: "出品日" },
            { key: "dispensation", label: "調剤実績" },
          ];
        case "buying":
          return [
            { key: "groups", label: "出品先" },
            { key: "seller_pharmacy_id", label: "出品薬局" },
            { key: "created_at", label: "出品日" },
            { key: "dealable_items.name", label: "医薬品名" },
            { key: "price", label: "出品額" },
            { key: "expires_on", label: "使用期限" },
            { key: "unpacked", label: "開封状況" },
            { key: "actions_default", label: "操作", sorter: false },
          ];
        case "selling":
          return [
            { key: "groups", label: "出品先" },
            { key: "created_at", label: "出品日" },
            { key: "dealable_items.name", label: "医薬品名" },
            { key: "price", label: "出品額" },
            { key: "expires_on", label: "使用期限" },
            { key: "unpacked", label: "開封状況" },
            { key: "actions_default", label: "操作", sorter: false },
          ];
        case "sell_dealing":
          return [
            { key: "groups", label: "出品先" },
            { key: "buyer_pharmacy_id", label: "購入薬局" },
            { key: "created_at", label: "出品日" },
            { key: "dealable_items.name", label: "医薬品名" },
            { key: "price", label: "出品額" },
            { key: "expires_on", label: "使用期限" },
            { key: "unpacked", label: "開封状況" },
            { key: "actions_default", label: "操作", sorter: false },
          ];
        case "done":
          return [
            { key: "groups", label: "出品先" },
            { key: "status", label: "取引状況" },
            { key: "seller_pharmacy_id", label: "出品薬局" },
            { key: "buyer_pharmacy_id", label: "購入薬局" },
            { key: "created_at", label: "出品日" },
            { key: "updated_at", label: "最終更新日" },
            { key: "dealable_items.name", label: "医薬品名" },
            { key: "price", label: "出品額" },
            { key: "actions_default", label: "操作", sorter: false },
          ];
        default:
          return [];
      }
    });
    const sorter = { external: true, resetable: false };
    const sorterValue = ref<Record<string, string | boolean>>({ column: "created_at", asc: false });
    const sorterField = computed(() => (sorterValue.value.asc ? sorterValue.value.column : `-${sorterValue.value.column}`));
    const currentPage = ref<number>(1);
    const totalPages = ref<number>(1);
    const totalCount = ref<number>(0);

    async function fetchDeals() {
      if (isLoading.value) return;

      try {
        isLoading.value = true;
        const req_params = {
          page: currentPage.value,
          query: query.value,
          sort: sorterField.value,
          dispensing_days: dispensingDays.value,
        };

        const response = await httpMedshare.get(dealUrl.value, { params: req_params });
        deals.value = response.data.map((d: Record<string, unknown>) => new Deal(d));

        currentPage.value = Number(response.headers["x-current-page"]);
        totalPages.value = Number(response.headers["x-total-pages"]);
        totalCount.value = Number(response.headers["x-total-count"]);

        const dealable_item_ids = deals.value
          .map((d: Deal) => d.dealable_item_id)
          .sort((a, b) => a - b)
          .join(",");
        const response2 = await httpMedshare.get(`${urlBase.value}/dispensed_items`, { params: { dealable_item_ids } });
        const dispensedItems = response2.data.map((d: Record<string, unknown>) => new DispensedItem(d));
        deals.value = deals.value.map((deal: Deal) => {
          deal.dispensed_item = dispensedItems.find((item: DispensedItem) => item.dealable_item_id === deal.dealable_item_id);
          return deal;
        });

        isFirstLoading.value = false;
      } finally {
        isLoading.value = false;
      }
    }

    function search(): void {
      currentPage.value = 1;
      fetchDeals();
    }

    function openBuyModal(deal: Deal): void {
      selectedDeal.value = deal;
      isOpenBuyModal.value = true;
    }

    watch(
      // props.type が変更されたら（つまり画面が切り替わったら）、再読込する。
      () => props.type,
      (type: string) => {
        isFirstLoading.value = true;
        if (type) fetchDeals();
      }
    );
    watch(query, (changed: string, original: string) => {
      // 検索ワードを入力し始めたときに、dispensingDays を null にする
      if (!original) dispensingDays.value = null;
    });
    watch(currentPage, () => fetchDeals());
    watch(sorterValue, () => fetchDeals());

    fetchDeals();

    return {
      isFirstLoading,
      isLoading,
      isOpenBuyModal,
      query,
      dispensingDays,
      deals,
      selectedDeal,
      fields,
      sorter,
      sorterValue,
      currentPage,
      totalPages,
      totalCount,
      search,
      fetchDeals,
      openBuyModal,
    };
  },
});
