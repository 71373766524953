import { getAuth0Client, hasAuth0PharmacyRole } from "@/store/auth0Store";
import { Pharmacy, usePharmaciesStore } from "@/store/pharmaciesStore";
import { createStoreProvider } from "@/store/utils";
import { useRoutePharmacyId } from "@/utils/routePharmacyId";
import { computed, watch } from "@vue/composition-api";
import Cookies from "js-cookie";
import Vue from "vue";

const SELECTING_PHARMACY_ID_COOKIE_NAME = "selecting_pharmacy_id";

interface State {
  cookiePharmacyId: number;
}

function createSelectingPharmacyStore() {
  // TODO: ユーザーが切り替わることを考えると、Cookie に保存するよりも、user ごとに設定をDBに持っていた方がいいかもしれない。
  const state = Vue.observable<State>({
    cookiePharmacyId: 0,
  });

  const routePharmacyId = useRoutePharmacyId();
  const pharmaciesStore = usePharmaciesStore();

  watch(routePharmacyId, (newPharmacyId) => {
    pharmaciesStore.fetch(newPharmacyId);
  });

  async function selectPharmacyId(pharmacyId: number) {
    state.cookiePharmacyId = pharmacyId;
    pharmaciesStore.fetch(pharmacyId);
    Cookies.set(SELECTING_PHARMACY_ID_COOKIE_NAME, String(state.cookiePharmacyId), { expires: new Date("9999-12-31") });
    // TODO: ↑ domain の範囲設定などを詳しくちゃんとやるべき。
  }

  async function removeSelectingPharmacy() {
    Cookies.remove(SELECTING_PHARMACY_ID_COOKIE_NAME);
    return true;
  }

  const selectingPharmacyId = computed(() => {
    if (routePharmacyId.value) {
      return routePharmacyId.value;
    }
    return state.cookiePharmacyId;
  });

  (async () => {
    const strPharmacyId = Cookies.get(SELECTING_PHARMACY_ID_COOKIE_NAME);
    if (!strPharmacyId) {
      return;
    }
    const pharmacyId = parseInt(strPharmacyId, 10);

    // Admin じゃない時は所属している薬局であることを確認する。
    await getAuth0Client(); // hasAuth0AdminRole は auth0Client が取れるようになってからアクセスする
    if (!hasAuth0PharmacyRole) {
      await pharmaciesStore.findList();
      const myPharmacies = pharmaciesStore.list.value;
      const index = myPharmacies.findIndex((myPharmacy: Pharmacy) => {
        return myPharmacy.id === pharmacyId;
      });
      if (index === -1) {
        return;
      }
    }

    state.cookiePharmacyId = pharmacyId;
    pharmaciesStore.fetch(pharmacyId);
  })();

  return {
    selectingPharmacyId,
    selectPharmacyId,
    removeSelectingPharmacy,
  };
}

const provider = createStoreProvider(createSelectingPharmacyStore, "SelectingPharmacyStore");

export const provideSelectingPharmacyStore = provider.provideStore;
export const useSelectingPharmacyStore = provider.useStore;
