var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isFirstLoading)?_c('LoadingAlert'):_c('CCard',[_c('CCardHeader',[_vm._v(_vm._s(_vm.totalCount.toLocaleString())+" 件の不動在庫を表示")]),_c('CCardBody',[_c('CForm',{on:{"submit":function($event){$event.preventDefault();return _vm.search($event)}}},[_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CInput',{directives:[{name:"c-popover",rawName:"v-c-popover",value:({
                header: '<strong>不動在庫期間</strong>とは',
                content:
                  'この日数の間に調剤がなかった医薬品を表示します。<br />' +
                  '「不動在庫期間」を短くして再計算すると、より多くの医薬品が表示されます。',
                placement: 'top-end',
              }),expression:"{\n                header: '<strong>不動在庫期間</strong>とは',\n                content:\n                  'この日数の間に調剤がなかった医薬品を表示します。<br />' +\n                  '「不動在庫期間」を短くして再計算すると、より多くの医薬品が表示されます。',\n                placement: 'top-end',\n              }"}],attrs:{"type":"number"},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_vm._v("不動在庫期間")]},proxy:true},{key:"append",fn:function(){return [_c('span',{staticClass:"input-group-text"},[_vm._v("日")]),_c('CButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.deadStockDays !== 180),expression:"deadStockDays !== 180"}],attrs:{"color":"info"},on:{"click":function($event){_vm.deadStockDays = 180}}},[_vm._v("180日に戻す")]),_c('CButton',{attrs:{"color":"primary","type":"submit"}},[_vm._v("再計算")])]},proxy:true}]),model:{value:(_vm.deadStockDays),callback:function ($$v) {_vm.deadStockDays=$$v},expression:"deadStockDays"}})],1),_c('CCol',{attrs:{"lg":"6"}},[_c('CInput',{attrs:{"placeholder":"医薬品名で検索"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('CButton',{attrs:{"color":"primary","type":"submit"}},[_vm._v("検索")])]},proxy:true}]),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1)],1),_c('CDataTable',{attrs:{"bordered":"","fixed":"","responsive":"","loading":_vm.isLoading,"items":_vm.items,"fields":_vm.fields,"sorter":_vm.sorter,"sorter-value":_vm.sorterValue},on:{"update:sorterValue":function($event){_vm.sorterValue=$event},"update:sorter-value":function($event){_vm.sorterValue=$event}},scopedSlots:_vm._u([{key:"sell",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"primary","size":"sm","to":{ name: '出品', params: { dealable_item: item.dealable_item } }}},[_vm._v("出品")])],1)]}},{key:"dealable_items.name",fn:function(ref){
              var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.dealable_item.name))])]}},{key:"dealable_items.price",fn:function(ref){
              var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.dealable_item.price)+" 円 / "+_vm._s(item.dealable_item.unit))])]}},{key:"last_dispensed_on",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('div',[_vm._v(_vm._s(_vm.toRelativeDate(item.last_dispensed_on)))]),_c('div',{staticClass:"small"},[_vm._v(_vm._s(item.last_dispensed_on))])])]}},{key:"dispensation_count",fn:function(ref){
              var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.dispensation_count.toLocaleString())+" 回")])]}},{key:"patient_count",fn:function(ref){
              var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.patient_count.toLocaleString())+" 人")])]}},{key:"last_deal_id",fn:function(ref){
              var item = ref.item;
return [_c('td',[(item.last_deal)?[_c('div',{staticClass:"small"},[_vm._v(_vm._s(item.last_deal_updated_on))]),_c('CBadge',{attrs:{"color":"info"}},[_vm._v(_vm._s(item.last_deal.display_relative_status))])]:_vm._e()],2)]}},{key:"status",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('DispensedItemStatusButton',{attrs:{"dispensed-item":item}})],1)]}}])}),_c('CPagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.totalPages > 1),expression:"totalPages > 1"}],attrs:{"align":"center","responsive":"","active-page":_vm.currentPage,"pages":_vm.totalPages,"limit":10},on:{"update:activePage":function($event){_vm.currentPage=$event},"update:active-page":function($event){_vm.currentPage=$event}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }