



























import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import { ReceiptImportFile } from "@/models/ReceiptImportFile";
import { useHttpCore } from "@/plugins/axios";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  components: { LoadingAlert },
  setup() {
    const httpCore = useHttpCore();

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId.value;

    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(false);
    const query = ref<string>("");
    const files = ref<Array<ReceiptImportFile>>([]);
    const fields = [
      { key: "file_name", label: "ファイル名" },
      { key: "status", label: "ステータス" },
      { key: "created_at", label: "アップロード日" },
    ];
    const sorter = { external: true, resetable: false };
    const sorterValue = ref<Record<string, string | boolean>>({ column: "id", asc: true });
    const currentPage = ref<number>(1);
    const totalPages = ref<number>(1);
    const totalCount = ref<number>(0);

    async function fetchReceipts() {
      if (isLoading.value) return;

      try {
        isLoading.value = true;
        const req_params = { page: currentPage.value, query: query.value };

        const response = await httpCore.get(`/api/v2/pharmacy/pharmacies/${myPharmacyId}/receipt_import_files`, { params: req_params });
        files.value = response.data.map((d: Record<string, unknown>) => new ReceiptImportFile(d));
        currentPage.value = Number(response.headers["x-current-page"]);
        totalPages.value = Number(response.headers["x-total-pages"]);
        totalCount.value = Number(response.headers["x-total-count"]);
        isFirstLoading.value = false;
      } finally {
        isLoading.value = false;
      }
    }

    fetchReceipts();

    return {
      isFirstLoading,
      isLoading,
      query,
      files,
      fields,
      sorter,
      sorterValue,
      currentPage,
      totalPages,
      totalCount,
    };
  },
});
