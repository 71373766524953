import { NavigationGuard } from "vue-router";
import Cookies from "js-cookie";

import { isAuth0Authenticated, getAuth0Client, hasAuth0PharmacyRole, hasAuth0AdminRole } from "@/store/auth0Store";

const SELECTING_PHARMACY_ID_COOKIE_NAME = "selecting_pharmacy_id";

export const authGuard: NavigationGuard = (to, from, next) => {
  (async () => {
    const isAuthenticated = await isAuth0Authenticated();
    if (!isAuthenticated) {
      const auth0Client = await getAuth0Client();
      auth0Client.loginWithRedirect({
        appState: {
          targetUrl: to.fullPath,
        },
      });
      return;
    }

    if (!hasAuth0PharmacyRole && !hasAuth0AdminRole) {
      next("/forbidden");
      return;
    }

    if (!Cookies.get(SELECTING_PHARMACY_ID_COOKIE_NAME)) {
      // TODO: ホントは Store 経由で参照したかったけど、いったんCookieを直接参照する。
      // リダイレクトループしないように、to.pathをチェック
      if (to.path !== "/select-pharmacy") {
        return next("/select-pharmacy");
      }
    }

    next();
  })();
};
