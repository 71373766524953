// Auth0 の Docs を参考に実装
import { User } from "@auth0/auth0-spa-js/src/global";
// https://auth0.com/docs/quickstart/spa/vuejs/01-login
import { SetupContext, ref } from "@vue/composition-api";
import { createStoreProvider } from "@/store/utils";

import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js";

import PromiseObserver from "@/utils/promiseObserver";

export interface Auth0User {
  name: string;
  picture: string;
}

const TENANT_DOMAIN = process.env.VUE_APP_AUTH0_TENANT_DOMAIN || "";
const CLIENT_ID = process.env.VUE_APP_AUTH0_CLIENT_ID || "";
const CUSTOM_CLAIMS = process.env.VUE_APP_AUTH0_CUSTOM_CLAIMS || "";
if (!TENANT_DOMAIN) {
  throw "Set VUE_APP_AUTH0_TENANT_DOMAIN in environment variables";
}
if (!CLIENT_ID) {
  throw "Set VUE_APP_AUTH0_CLIENT_ID in environment variables";
}
if (!CUSTOM_CLAIMS) {
  throw "Set VUE_APP_AUTH0_CUSTOM_CLAIMS in environment variables";
}

// jwtToken の有効期間(ミリ秒)
// Auth0 の ID Token Expiration の設定値よりも短い時間を設定しておくこと
const JWT_TOKEN_EXPIRATION = 60 * 60 * 1000; // 1時間

// checkSession が呼ばれる間隔(ミリ秒)
// jwtToken の有効期間が切れる前に checkSession が呼ばれる必要があるので、
// JWT_TOKEN_EXPIRATION より短い時間を設定すること
const CHECK_SESSION_INTERVAL = (60 * 60 - 10) * 1000; // 1時間 - 10秒

let auth0ClientInstance: Auth0Client | null = null;
export let hasAuth0PharmacyRole = false;
export let hasAuth0AdminRole = false;
export let auth0PharmacyIds: number[] = [];
let auth0JwtToken = "";
let auth0JwtTokenExpration = 0;
let isCheckingSession = false;
const promiseObserver = new PromiseObserver();

export async function getAuth0Client(): Promise<Auth0Client> {
  if (auth0ClientInstance) {
    return auth0ClientInstance;
  }

  await promiseObserver.createWaitPromise();
  if (!auth0ClientInstance) {
    throw "createAuth0Client() faild.";
  }
  return auth0ClientInstance;
}

export async function isAuth0Authenticated(): Promise<boolean> {
  const auth0Client = await getAuth0Client();
  return auth0Client.isAuthenticated();
}

async function setAuth0JwtToken(auth0Client: Auth0Client): Promise<void> {
  const claims = await auth0Client.getIdTokenClaims();
  if (!claims) {
    return;
  }
  auth0JwtToken = claims.__raw;
  auth0JwtTokenExpration = Date.now() + JWT_TOKEN_EXPIRATION;
}

async function checkSession(): Promise<void> {
  if (isCheckingSession) {
    return;
  }
  isCheckingSession = true;
  const auth0Client = await getAuth0Client();
  await auth0Client.checkSession();
  setAuth0JwtToken(auth0Client);
  isCheckingSession = false;
  promiseObserver.notifyAll();

  setTimeout(checkSession, CHECK_SESSION_INTERVAL);
}

// ID Token Expiration を過ぎると、getIdTokenClaims() が null を返すようになるので、
// その前に checkSession() を呼んで、トークンをリフレッシュさせる。
// checkSession() が数百ミリ秒ほど処理時間がかかるので毎回呼ばないようにしているのと、
// checkSession() を呼んでいる間は getIdTokenClaims() が null を返すので、 auth0JwtToken に
// 以前の値を保持しておくようにしておく。
export async function getAuth0JwtToken(): Promise<string> {
  if (Date.now() > auth0JwtTokenExpration) {
    checkSession();
    await promiseObserver.createWaitPromise();
  }
  return auth0JwtToken;
}

function createAuth0Store(context: SetupContext) {
  const auth0User = ref<Auth0User | User | null | undefined>(null);

  (async () => {
    auth0ClientInstance = await createAuth0Client({
      domain: TENANT_DOMAIN,
      client_id: CLIENT_ID,
      redirect_uri: window.location.origin,
    });

    try {
      // If the user is returning to the app after authentication..
      if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
        // handle the redirect and retrieve tokens
        const { appState } = await auth0ClientInstance.handleRedirectCallback();

        // Notify subscribers that the redirect callback has happened, passing the appState
        // (useful for retrieving any pre-authentication state)
        context.root.$router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
      }
    } finally {
      if (await auth0ClientInstance.isAuthenticated()) {
        setAuth0JwtToken(auth0ClientInstance);
        setTimeout(checkSession, CHECK_SESSION_INTERVAL);
      }

      const anyUser = await auth0ClientInstance.getUser();
      auth0User.value = anyUser;
      if (anyUser) {
        const claims = anyUser[CUSTOM_CLAIMS];
        const roles = claims["roles"];
        hasAuth0PharmacyRole = roles.includes("pharmacy");
        hasAuth0AdminRole = roles.includes("admin");
        const appMetadata = claims["app_metadata"];
        const strPharmacyIds: string[] = appMetadata["pharmacy_ids"];
        auth0PharmacyIds = strPharmacyIds.map((str) => {
          return parseInt(str, 10);
        });
      }
      promiseObserver.notifyAll();
    }
  })();

  async function logout() {
    const auth0Client = await getAuth0Client();
    auth0Client.logout({
      returnTo: window.location.origin,
    });
  }

  return {
    auth0User,
    logout,
  };
}

const provider = createStoreProvider(createAuth0Store, "Auth0Store");

export const provideAuth0Store = provider.provideStore;
export const useAuth0Store = provider.useStore;
