







































import Vue from "vue";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";

import { hasAuth0AdminRole } from "@/store/auth0Store";
import { usePharmaciesStore, Pharmacy } from "@/store/pharmaciesStore";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { useRoutePharmacyId } from "@/utils";

const showEvent = new Vue();
export function showSelectPharmacyModal(): void {
  showEvent.$emit("show");
}

export default defineComponent({
  setup(props, context) {
    const pharmaciesStore = usePharmaciesStore();
    const selectingPharmacyStore = useSelectingPharmacyStore();

    const AdminPharmacyIdsKey = "adminPharmacyIds";
    const adminPharmacyIds = ref<number[]>([]);
    function loadAdminPharmacyIds() {
      const strPharmacyIds = localStorage.getItem(AdminPharmacyIdsKey);
      if (strPharmacyIds) {
        adminPharmacyIds.value = strPharmacyIds.split(",").map((str) => parseInt(str, 10));
      }
    }

    // 表示の管理
    const isShow = ref(false);
    showEvent.$on("show", () => {
      pharmaciesStore.findList();

      if (hasAuth0AdminRole) {
        loadAdminPharmacyIds();
        pharmaciesStore.fetch(adminPharmacyIds.value);
      }

      isShow.value = true;
    });

    async function selectPharmacy(pharmacyId: number) {
      // 選択薬局を更新する
      selectingPharmacyStore.selectPharmacyId(pharmacyId);

      // 強制リロードする
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      context.root.$router.go({ path: this.$router.currentRoute.path, force: true });
    }

    // 管理者の場合は薬局の閲覧履歴を表示させる
    const adminPharmacies = (() => {
      if (!hasAuth0AdminRole) {
        return computed(() => {
          return null;
        });
      }

      return computed(() => {
        pharmaciesStore.isFetching.value;
        const pharmacies: Pharmacy[] = [];
        for (const pharmacyId of adminPharmacyIds.value) {
          const pharmacy = pharmaciesStore.get(pharmacyId) as Pharmacy;
          if (!pharmacy) {
            continue;
          }
          pharmacies.push(pharmacy);
        }
        return pharmacies;
      });
    })();

    if (hasAuth0AdminRole) {
      const routePharmacyId = useRoutePharmacyId();
      watch(routePharmacyId, (newPharmacyId) => {
        if (!newPharmacyId) {
          return;
        }
        pharmaciesStore.fetch(newPharmacyId);
        loadAdminPharmacyIds();
        const index = adminPharmacyIds.value.indexOf(newPharmacyId);
        if (index !== -1) {
          adminPharmacyIds.value.splice(index, 1);
        }
        adminPharmacyIds.value.unshift(newPharmacyId);
        if (adminPharmacyIds.value.length >= 10) {
          adminPharmacyIds.value.splice(9, 1);
        }
        localStorage.setItem(AdminPharmacyIdsKey, adminPharmacyIds.value.join(","));
      });
    }

    return {
      pharmacies: pharmaciesStore.list,
      selectPharmacy,
      isShow,
      adminPharmacies,
    };
  },
});
