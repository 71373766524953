

































































import ReceiptUploadStatus from "@/components/receipts/ReceiptUploadStatus.vue";
import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import { Receipt } from "@/models/Receipt";
import { ReceiptSummary } from "@/models/ReceiptSummary";
import { useHttpCore } from "@/plugins/axios";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  components: { LoadingAlert, ReceiptUploadStatus },
  setup() {
    const httpCore = useHttpCore();

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId.value;

    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(false);
    const query = ref<string>("");
    const files = ref<Array<Receipt>>([]);
    const summary = ref<ReceiptSummary | undefined>(undefined);
    const fields = [
      { key: "year", label: "年" },
      { key: "insurance", label: "保険機関" },
      { key: "1", label: "1月" },
      { key: "2", label: "2月" },
      { key: "3", label: "3月" },
      { key: "4", label: "4月" },
      { key: "5", label: "5月" },
      { key: "6", label: "6月" },
      { key: "7", label: "7月" },
      { key: "8", label: "8月" },
      { key: "9", label: "9月" },
      { key: "10", label: "10月" },
      { key: "11", label: "11月" },
      { key: "12", label: "12月" },
    ];
    const sorter = { external: true, resetable: false };
    const sorterValue = ref<Record<string, string | boolean>>({ column: "id", asc: true });
    const currentPage = ref<number>(1);
    const totalPages = ref<number>(1);
    const totalCount = ref<number>(0);

    async function fetchReceipts() {
      if (isLoading.value) return;

      try {
        isLoading.value = true;
        const req_params = { page: currentPage.value, query: query.value };

        const response = await httpCore.get(`/api/v2/pharmacy/pharmacies/${myPharmacyId}/receipts`, { params: req_params });
        files.value = response.data.map((d: Record<string, unknown>) => new Receipt(d));
        summary.value = new ReceiptSummary(files.value);

        currentPage.value = Number(response.headers["x-current-page"]);
        totalPages.value = Number(response.headers["x-total-pages"]);
        totalCount.value = Number(response.headers["x-total-count"]);
        isFirstLoading.value = false;
      } finally {
        isLoading.value = false;
      }
    }

    function displayYear(yearInsurance: Record<string, string>): string | null {
      if (yearInsurance.insurance === "1") {
        return yearInsurance.year;
      } else {
        return null;
      }
    }

    function displayInsurance(yearInsurance: Record<string, string>): string {
      switch (yearInsurance.insurance) {
        case "1":
          return "社保";
        case "2":
          return "国保";
        default:
          return "不明";
      }
    }

    fetchReceipts();

    return {
      isFirstLoading,
      isLoading,
      query,
      files,
      summary,
      fields,
      sorter,
      sorterValue,
      currentPage,
      totalPages,
      totalCount,
      displayYear,
      displayInsurance,
    };
  },
});
