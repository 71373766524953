

















import { DispensedItem } from "@/models/DispensedItem";
import { useHttpMedshare } from "@/plugins/axios";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  props: {
    dispensedItem: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const httpMedshare = useHttpMedshare();

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId.value;

    const dispensedItem = ref<DispensedItem>(props.dispensedItem as DispensedItem);
    const url = computed(() => `/api/v2/pharmacy/pharmacies/${myPharmacyId}/dispensed_items/${dispensedItem.value.id}`);

    const isLoading = ref<boolean>(false);
    const status = ref<string>((props.dispensedItem as DispensedItem).status);

    async function changeStatus(changed: string) {
      try {
        isLoading.value = true;
        await httpMedshare.patch(url.value, { status: changed });
        status.value = changed;
      } finally {
        isLoading.value = false;
      }
    }

    watch(props, () => {
      dispensedItem.value = props.dispensedItem as DispensedItem;
      status.value = dispensedItem.value.status;
    });

    return {
      isLoading,
      status,
      inUseTooltip: {
        appendToBody: true,
        placement: "top-end",
        content: "「表示」に設定すると出品提案に表示されます。",
      },
      noStockTooltip: {
        appendToBody: true,
        placement: "top-end",
        content:
          "「期限切れ/在庫なし」に設定すると一時的に出品提案に出なくなります。しかし新たに調剤が起こった場合は、自動的に「表示」に戻ります。",
      },
      reservedTooltip: {
        appendToBody: true,
        placement: "top-end",
        content: "「非表示」に設定すると出品提案に出なくなります。",
      },
      changeStatus,
    };
  },
});
